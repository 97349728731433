import { useRequest } from "ahooks";

import { Service } from "../../../../../../api/HTTPClient/HTTPClient";

const token = "3f4a0822e216bfa10c8c0c133469387fdfdafcca";
// const secret = "2ffcf51c52af4bc129b35440c9fb262c6dcbb0a0";

const useSuggestions = (type: SuggestionsType, options?: ObjectRecord) => {
	const service = new Service("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/");

    const request = (query: string) => {
		return new Promise((resolve, reject) => {
			service.request({
				url: type,
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Token ${token}`
				},
				withCredentials: false,
				data: JSON.stringify({ query })
			})
				.then(response => resolve(response?.data?.suggestions ?? []))
				.catch(reject);
		});
	};

	return useRequest(request, options)
}

export default useSuggestions;
