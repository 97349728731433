import { FC, useMemo } from 'react';
// import classNames from 'classnames';
import { Button, Col, Row, Collapse } from 'antd';
import { withConfigConsumer } from 'antd/lib/config-provider/context';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import ArrayFieldTemplateItem from '../ArrayFieldTemplateItem';

const DESCRIPTION_COL_STYLE = {
	paddingBottom: 10,
};

const { Panel } = Collapse;

const FixedArrayFieldTemplate: FC<RJSFWidget> = ({
	id,
	canAdd,
	className,
	DescriptionField,
	disabled,
	formContext,
	// formData,
	idSchema,
	items,
	onAddClick,
	// prefixCls,
	readonly,
	// registry,
	required,
	schema,
	title,
	TitleField,
	uiSchema,
}): JSX.Element => {
	const { /* labelAlign = 'right', */ rowGutter = 24, isCollapsible = true } = formContext;

	// const labelClsBasic = `${prefixCls}-item-label`;
	// const labelColClassName = classNames(
	// 	labelClsBasic,
	// 	labelAlign === 'left' && `${labelClsBasic}-left`,
	// 	// labelCol.className,
	// );

	title = title || id || idSchema?.$id;

	const Fieldset = useMemo(() => {
		return (
			<fieldset
				className={className}
				id={idSchema.$id}
				style={{
					border: '1px solid #d9d9d9',
					paddingLeft: 10,
					paddingRight: 10,
				}}
			>
				<Row gutter={rowGutter}>
					{/* {title && (
						<Col className={labelColClassName} span={24}>
							<TitleField
								id={`${idSchema.$id}__title`}
								key={`array-field-title-${idSchema.$id}`}
								required={required}
								title={uiSchema['ui:title'] || title}
							/>
						</Col>
					)} */}

					{(uiSchema['ui:description'] || schema.description) && (
						<Col span={24} style={DESCRIPTION_COL_STYLE}>
							<DescriptionField
								description={uiSchema['ui:description'] || schema.description}
								id={`${idSchema.$id}-description`}
								key={`array-field-description-${idSchema.$id}`}
							/>
						</Col>
					)}

					<Col className="row array-item-list" span={24}>
						{items &&
							items.map((itemProps: any) => (
								<ArrayFieldTemplateItem
									{...itemProps}
									formContext={formContext}
								/>
							))}
					</Col>

					{
						canAdd && (
							<Col span={24}>
								<Row gutter={rowGutter} justify="end">
									<Col>
										<Button
											block
											// className="array-item-add"
											disabled={disabled || readonly}
											onClick={onAddClick}
											type="primary"
											size={'small'}
											icon={<PlusCircleOutlined />}
										/>
									</Col>
								</Row>
							</Col>
						)
					}
				</Row>
			</fieldset>
		)
	}, [DescriptionField, canAdd, className, disabled, formContext, idSchema.$id, items, onAddClick, readonly, rowGutter, schema.description, uiSchema]);

	if(!isCollapsible) {
		return Fieldset;
	};

	return (
		<Collapse
			bordered={false}
			defaultActiveKey={[idSchema.$id]}
			expandIcon={({ isActive }) =>
				!isActive ? <PlusCircleOutlined /> : <MinusCircleOutlined />
			}
		>
			<Panel
				key={idSchema.$id}
				header={
					!!title && (
						<TitleField
							id={`${idSchema.$id}__title`}
							key={`array-field-title-${idSchema.$id}`}
							required={required}
							title={uiSchema['ui:title'] || title}
						/>
					)
				}
			>
				{Fieldset}
			</Panel>
		</Collapse>
	);
};

FixedArrayFieldTemplate.displayName = "FixedArrayFieldTemplate";

export default withConfigConsumer({ prefixCls: 'form' })(FixedArrayFieldTemplate);
