import { FC, useCallback } from 'react';
import { Checkbox } from 'antd';

const CheckboxWidget: FC<RJSFWidget> = ({
	autofocus,
	disabled,
	formContext,
	id,
	label,
	// onBlur,
	onChange,
	// onFocus,
	readonly,
	value,
}) => {
	const { readonlyAsDisabled = true } = formContext;

	const handleChange = useCallback(({ target }: any) => {
		onChange(target.checked)
	}, [onChange]);

	// const handleBlur = ({ target }) => onBlur(id, target.checked);

	// const handleFocus = ({ target }) => onFocus(id, target.checked);

	return (
		<Checkbox
			autoFocus={autofocus}
			checked={typeof value === 'undefined' ? false : value}
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			// onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			// onFocus={!readonly ? handleFocus : undefined}
		>
			{label}
		</Checkbox>
	);
};

export default CheckboxWidget;

CheckboxWidget.displayName = "CheckboxWidget";
