//@ts-nocheck
import { FC, useCallback, useState } from 'react';
import { Button, Checkbox, Divider, Typography, Drawer, Row, Col, message } from 'antd';
import { DownloadOutlined, FileSearchOutlined } from '@ant-design/icons';
import { isIOS, isMobile } from "react-device-detect";

import qrCode from './qr_download_goskluch.png'
import { useSign, useDownload } from '../../../hooks';
import PDFViewer from '../../PDFViewer/PDFViewer';
import RJSFForm from "../../Form";
import State from "../../../containers/State";

import handleDownload from "../../../helpers/handleDownload";

const { Title } = Typography;

const initModalState = { isOpen: false, url: "", title: "" };

const Created: FC<StateProps> = ({ form, onFinish }: { onFinish: () => {} }): JSX.Element => {
	const [isRemeber, setIsRemember] = useState<boolean>(false);
	const [modal, setModal] = useState<ObjectRecord>(initModalState);
	const [defaultData, setDefaultData] = useState<ObjectRecord>(form?.form_data ?? {});

	const {
		loading: signLoading,
		runAsync: startSign
	} = useSign({ manual: true });

	const onFileError = useCallback((error: Error) => {
		console.log(JSON.stringify(error, null, 4));

		message.error("Ошибка загрузки файла.");
	}, []);

	const {
		loading: fileDownloading,
		run: handleFileDownload,
	} = useDownload({
		isIOS,
		manual: true,
		onSuccess: handleDownload,
		onError: onFileError
	});

	const {
		loading: fileLoading,
		run: handleFilePreview,
	} = useDownload({
		manual: true,
		onSuccess: (file: DownloadFile) => {
			setModal({
				isOpen: true,
				url: file?.url,
				title: file?.filename
			});
		},
		onError: onFileError
	});

	const handleFinish = useCallback((formData: ObjectRecord) => {
		if(isRemeber) {
			startSign(JSON.stringify({ document: formData }))
				.finally(onFinish);
		}
	}, [isRemeber, startSign, onFinish]);

	return (
		<State>
			<Divider />

			<Row>
				<Col>
					<RJSFForm
						id={"cs-create-form"}
						noHtml5Validate={false}
						schema={form?.schema}
						uiSchema={form?.ui_schema}
						formData={defaultData}
						onSubmit={({ formData }) => handleFinish(formData)}
						onChange={({ formData }) => setDefaultData(formData)}
						formContext={{ isCollapsible: false }}
					/>
				</Col>
				<Col span={24}>
					<Title level={5}>
						Перед тем, как направить документ на подпись в Госключ, ознакомьтесь с его содержимым
					</Title>
				</Col>

				<Col span={24}>
					<Row>
						<Col span={24} lg={{ span: 12 }}>
							<Button
								loading={fileDownloading}
								disabled={fileDownloading}
								type="link"
								icon={<DownloadOutlined />}
								onClick={() => handleFileDownload(
										JSON.stringify({
											document: {
												data: defaultData
											}
										})
									)
								}
							>
								Скачать документ
							</Button>
						</Col>

						<Col span={24} lg={{ span: 12 }}>
							<Button
								loading={fileLoading}
								disabled={fileLoading}
								type="link"
								icon={<FileSearchOutlined />}
								onClick={() => handleFilePreview(
										JSON.stringify({
												document: {
													data: defaultData
												}
											}
										)
									)
								}
							>
								Посмотреть документ
							</Button>
						</Col>
					</Row>
				</Col>

				<Divider />

				<Col span={24}>
					<Row justify="space-between" align="bottom">
						<Col span={24}>
							<Row gutter={[0, 24]}>
								<Col span={24} lg={{ span: 16 }}>
									<Col span={24}>
										<Checkbox checked={isRemeber} onChange={event => setIsRemember(event?.target?.checked)}>
											Документ прочитал, согласен, готов подписать
										</Checkbox>
									</Col>
									<Col span={12} xs={{ span: 24 }}>
										<Button
											form={"cs-create-form"}
											loading={signLoading}
											disabled={!isRemeber || signLoading}
											type="primary"
											htmlType="submit"
										>
											Направить в Госключ
										</Button>
									</Col>
								</Col>
								<Col span={24} lg={{ span: 8 }}>
									<Row justify={"center"}>
										{
											!isMobile && (
												<Col xs={{ span: 24 }}>
													<Row justify={"center"}>
														<img
															src={qrCode}
															alt="gq-code"
															style={{ width: 150 }}
														/>
													</Row>
												</Col>
											)
										}
										<Col xs={{ span: 24 }}>
											<Row justify={"center"}>
												{
													isMobile && (
														<Button type={"link"} href={"https://api.cloudsigns.ru/qr/download_goskluch"} target={"_blank"}>
															Скачать приложение Госключ.
														</Button>
													)
												}

												{
													!isMobile && (
														<div style={{ fontSize: 12 }}>
															Скачать приложение Госключ.
														</div>
													)
												}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Drawer
				destroyOnClose
				className={"cs-pdf"}
				title={modal?.title}
				open={modal?.isOpen}
				onClose={() => setModal(initModalState)}
				height={window?.innerHeight}
				footer={null}
				placement={"bottom"}
			>
				<PDFViewer
					id={modal?.title}
					title={modal?.title}
					url={modal?.url}
				/>
			</Drawer>
		</State>
	);
}

export default Created;

Created.displayName = "CreatedState";
