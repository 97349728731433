import { useRequest } from "ahooks";

import service from "../../api/HTTPClient";

const useStatus = (options?: ObjectRecord) => {
    const request = () => {
		return new Promise((resolve, reject) => {
			service.request({ method: "GET" })
				.then(response => resolve(response?.data))
				.catch(reject);
		});
	};

	return useRequest(request, options)
}

export default useStatus;
