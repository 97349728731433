import { FC } from 'react';
import { Input } from 'antd';

const PasswordWidget: FC<RJSFWidget> = ({
	disabled,
	formContext,
	id,
	onBlur,
	onChange,
	onFocus,
	options,
	placeholder,
	readonly,
	value,
}): JSX.Element => {
	const { readonlyAsDisabled = true } = formContext;

	const emptyValue = options.emptyValue || '';

	const handleChange = ({ target }: any) => onChange(target.value === '' ? emptyValue : target.value);

	const handleBlur = ({ target }: any) => onBlur(id, target.value);

	const handleFocus = ({ target }: any) => onFocus(id, target.value);

	return (
		<Input.Password
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			placeholder={placeholder}
			value={value || ''}
		/>
	);
};

export default PasswordWidget;

PasswordWidget.displayName = "PasswordWidget";
