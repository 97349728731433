import { FC } from 'react';
import { Radio, Space } from 'antd';

const RadioWidget: FC<RJSFWidget> = ({
	autofocus,
	disabled,
	formContext,
	id,
	onBlur,
	// onChange,
	onFocus,
	options,
	readonly,
	// schema,
	value,
}): JSX.Element => {
	const { readonlyAsDisabled = true } = formContext;

	const { enumOptions, enumDisabled } = options;

	// const handleChange = ({ target: { value: nextValue } }) =>
	// 	onChange(schema.type === 'boolean' ? nextValue !== 'false' : nextValue);

	const handleBlur = ({ target }: any) => onBlur(id, target.value);

	const handleFocus = ({ target }: any) => onFocus(id, target.value);

	return (
		<Radio.Group
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			// onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			value={`${value}`}
		>
			<Space direction="vertical">
				{enumOptions.map(({ value: optionValue, label: optionLabel }: any, idx: number) => (
					<Radio
						autoFocus={idx === 0 ? autofocus : false}
						disabled={enumDisabled && enumDisabled.indexOf(value) !== -1}
						key={`${optionValue}`}
						value={`${optionValue}`}
					>
						{optionLabel}
					</Radio>
				))}
			</Space>
		</Radio.Group>
	);
};

export default RadioWidget;

RadioWidget.displayName = "RadioWidget";
