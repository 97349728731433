import { isYandex, isIOS } from "react-device-detect";

// NOTE: Cross-platform file downloader

const handleDownload = (file: DownloadFile): void => {
	const url = file?.url;
	const filename = file?.filename

	if(isYandex && isIOS) {
		window.open(url, "_blank", "download");

		return;
	}

	const link = document.createElement("a");

	link.href = url;
	link.target = "_blank";
	link.download = filename ?? url;
	link.click();
};

export default handleDownload;
