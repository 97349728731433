import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/lib/date-picker/generatePicker";

import "antd/es/date-picker/style/index";

// TODO: need to use datePicker from antd

const DatePicker = generatePicker(dayjsGenerateConfig);

export default DatePicker;
