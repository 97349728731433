import { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';

const BTN_GRP_STYLE = {
	// width: '100%',
	marginTop: 10,
};

// const BTN_STYLE = {
// 	width: 'calc(100% / 3)',
// };

// TODO: May not field ?
const ArrayFieldTemplateItem: FC<RJSFWidget> = ({
	canSwap = true,
	children,
	disabled,
	formContext,
	hasMoveDown,
	hasMoveUp,
	hasRemove,
	hasToolbar,
	index,
	onDropIndexClick,
	onReorderClick,
	readonly,
	extra
}) => {
	const { rowGutter = 24, toolbarAlign = 'middle' } = formContext;

	return (
		<Row
			align={toolbarAlign}
			key={`array-item-${index}`}
			gutter={rowGutter}
			style={{ marginBottom: 10, padding: 10 }}
		>
			<Col span={24}>
				{children}
			</Col>

			{
				hasToolbar && (
					<Col span={24}>
						<Row justify={"end"}>
							<Button.Group style={BTN_GRP_STYLE}>
								{extra}

								{
									(hasMoveUp || hasMoveDown) && canSwap && (
										<Button
											disabled={disabled || readonly || !hasMoveUp}
											icon={<ArrowUpOutlined />}
											onClick={onReorderClick(index, index - 1)}
											type={'default'}
											size={'small'}
										/>
									)
								}

								{
									(hasMoveUp || hasMoveDown) && canSwap && (
										<Button
											disabled={disabled || readonly || !hasMoveDown}
											icon={<ArrowDownOutlined />}
											onClick={onReorderClick(index, index + 1)}
											type={'default'}
											size={'small'}
										/>
									)
								}

								{
									hasRemove && (
										<Button
											danger
											disabled={disabled || readonly}
											icon={<DeleteOutlined />}
											onClick={onDropIndexClick(index)}
											type={'primary'}
											size={'small'}
										/>
									)
								}
							</Button.Group>
						</Row>
					</Col>
				)
			}
		</Row>
	);
};

export default ArrayFieldTemplateItem;

ArrayFieldTemplateItem.displayName = "ArrayFieldTemplateItem";
ArrayFieldTemplateItem.defaultProps = {
	formContext: {},
};
