// @ts-nocheck
import { FC, useMemo } from "react";
import { Button, Col, Row, Collapse } from 'antd';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { utils, FieldProps } from '@rjsf/core';
import { withConfigConsumer } from 'antd/lib/config-provider/context';

const { canExpand } = utils;
const { Panel } = Collapse;

const DESCRIPTION_COL_STYLE = {
	paddingBottom: 10,
};

function Wrapper({ props, children, isCollapsible }: any) {
	const { title, idSchema, uiSchema, TitleField, required } = props;

	return idSchema.$id !== 'root' && isCollapsible ? (
		<Collapse
			bordered={false}
			defaultActiveKey={[idSchema.$id]}
			expandIcon={({ isActive }) =>
				!isActive ? <PlusCircleOutlined /> : <MinusCircleOutlined />
			}
		>
			<Panel
				key={idSchema.$id}
				header={
					uiSchema['ui:title'] !== false &&
					(uiSchema['ui:title'] || title) && (
						<TitleField
							id={`${idSchema.$id}-title`}
							required={required}
							title={uiSchema['ui:title'] || title}
						/>
					)
				}
			>
				{children}
			</Panel>
		</Collapse>
	) : (
		<div
			style={{
				backgroundColor: '#fafafa',
			}}
		>
			{children}
		</div>
	);
}

const ObjectFieldTemplate: FC<FieldProps> = ({
	id,
	DescriptionField,
	TitleField,
	description,
	disabled,
	formContext,
	formData,
	idSchema,
	onAddClick,
	prefixCls,
	properties,
	readonly,
	required,
	schema,
	title,
	uiSchema,
}) => {
	const { colSpan = 24, /* labelAlign = 'right', */ rowGutter = 20, isCollapsible = true } = formContext;

	// const labelClsBasic = `${prefixCls}-item-label`;
	// const labelColClassName = classNames(
	// 	labelClsBasic,
	// 	labelAlign === 'left' && `${labelClsBasic}-left`,
	// 	// labelCol.className,
	// );

	const findSchema = (element: any) => element.content.props.schema;

	const findSchemaType = (element: any) => findSchema(element).type;

	const findUiSchema = (element: any) => element.content.props.uiSchema;

	const findUiSchemaField = (element: any) => findUiSchema(element)['ui:field'];

	const findUiSchemaWidget = (element: any) => findUiSchema(element)['ui:widget'];

	const calculateColSpan = (element: any) => {
		const type = findSchemaType(element);
		const field = findUiSchemaField(element);
		const widget = findUiSchemaWidget(element);

		const defaultColSpan =
			properties.length < 2 || // Single or no field in object.
			type === 'object' ||
			type === 'array' ||
			widget === 'textarea'
				? 24
				: 12;

		if (isObject(colSpan)) {
			return colSpan[widget] || colSpan[field] || colSpan[type] || defaultColSpan;
		}
		if (isNumber(colSpan)) {
			return colSpan;
		}
		return defaultColSpan;
	};

	const isExpand = useMemo(() => {
		return canExpand(schema, uiSchema, formData);
	}, [formData, schema, uiSchema]);

	title = title || id || idSchema?.$id;

	return (
		<Wrapper title={title} props={{ idSchema, uiSchema, TitleField, required, isCollapsible }}>
			<fieldset
				id={idSchema.$id}
				style={{
					border: '1px solid #d9d9d9',
					paddingLeft: 10,
					paddingRight: 10,
					paddingTop: 10,
				}}
			>
				<Row gutter={rowGutter}>
					{/* {uiSchema['ui:title'] !== false && (uiSchema['ui:title'] || title) && (
						<Col className={labelColClassName} span={24}>
							<TitleField
								id={`${idSchema.$id}-title`}
								required={required}
								title={uiSchema['ui:title'] || title}
							/>
						</Col>
					)} */}
					<Col span={24}>
						<Row gutter={rowGutter} wrap>
							{uiSchema['ui:description'] !== false &&
								(uiSchema['ui:description'] || description) && (
									<Col span={24} style={DESCRIPTION_COL_STYLE}>
										<DescriptionField
											description={uiSchema['ui:description'] || description}
											id={`${idSchema.$id}-description`}
										/>
									</Col>
								)}
							{
								properties
									.filter((e) => !e.hidden)
									.map((element) => (
										<Col
											key={element.name}
											span={calculateColSpan(element)}
											style={{ marginBottom: 20 }}
										>
											{element.content}
										</Col>
									))
								}
						</Row>
					</Col>
				</Row>

				{
					isExpand && (
						<Col span={24}>
							<Row gutter={rowGutter} justify="end">
								<Col>
									<Button
										className="object-property-expand"
										disabled={disabled || readonly}
										onClick={onAddClick(schema)}
										type={'primary'}
										size={'small'}
										icon={<PlusCircleOutlined />}
									/>
								</Col>
							</Row>
						</Col>
					)
				}
			</fieldset>
		</Wrapper>
	);
};

ObjectFieldTemplate.displayName = "ObjectFieldTemplate";

export default withConfigConsumer({ prefixCls: 'form' })(ObjectFieldTemplate);
