import { FC } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { utils } from '@rjsf/core';

import CustomLabel from '../../components/CustomLabel';

const { ADDITIONAL_PROPERTY_FLAG } = utils;

const VERTICAL_LABEL_COL = { span: 24 };
const VERTICAL_WRAPPER_COL = { span: 24 };

const INPUT_STYLE = {
	width: '100%',
};

const WrapIfAdditional: FC<RJSFWidget> = ({
	children,
	classNames,
	disabled,
	formContext,
	id,
	label,
	onDropPropertyClick,
	onKeyChange,
	readonly,
	required,
	schema,
	description,
}) => {
	const {
		colon,
		labelCol = VERTICAL_LABEL_COL,
		readonlyAsDisabled = true,
		rowGutter = 24,
		toolbarAlign = 'top',
		wrapperCol = VERTICAL_WRAPPER_COL,
		wrapperStyle,
	} = formContext;

	const keyLabel = `${label} Key`; // i18n ?
	const additional = schema.hasOwnProperty(ADDITIONAL_PROPERTY_FLAG);

	if (!additional) {
		return (
			<div className={classNames}>
				{children}
			</div>
		);
	}

	const handleBlur = ({ target }: any) => onKeyChange(target.value);

	return (
		<div className={classNames}>
			<Row align={toolbarAlign} gutter={rowGutter}>
				<Col className="form-additional" flex="1">
					<div className="form-group">
						<Form.Item
							colon={colon}
							className="form-group"
							hasFeedback
							htmlFor={`${id}-key`}
							label={
								<CustomLabel id={id} label={keyLabel} description={description} />
							}
							labelCol={labelCol}
							required={required}
							style={wrapperStyle}
							wrapperCol={wrapperCol}
						>
							<Input
								className="form-control"
								defaultValue={label}
								disabled={disabled || (readonlyAsDisabled && readonly)}
								id={`${id}-key`}
								name={`${id}-key`}
								onBlur={!readonly ? handleBlur : undefined}
								style={INPUT_STYLE}
								type="text"
							/>
						</Form.Item>
					</div>
				</Col>
				<Col className="form-additional" flex="1">
					{children}
				</Col>
				<Col flex="192px">
					<Button
						className="array-item-remove"
						danger
						disabled={disabled || readonly}
						icon={<DeleteOutlined />}
						onClick={onDropPropertyClick(label)}
						type="primary"
					/>
				</Col>
			</Row>
		</div>
	);
};

export default WrapIfAdditional;

WrapIfAdditional.displayName = "WrapIfAdditional";
