import SuggestionsWidget from "../SuggestionsWidget";

// TODO: Move to helpers
const format = (suggestions: any[]) => {
	return suggestions.map(({ value, data, ...options }) => {
		return {
			value: [value, data?.inn].join(", "),
			data,
			...options
		}
	});
};

function OrganizationSuggestionsWidget(props: RJSFWidget) {
	return <SuggestionsWidget {...props} options={{ type: "party" }} format={format} />;
}

export default OrganizationSuggestionsWidget;

OrganizationSuggestionsWidget.displayName = "OrganizationSuggestionsWidget";
