import { FC } from "react";
import { FieldProps } from "@rjsf/core";

import OrganizationSuggestionsWidget from "../../widgets/OrganizationSuggestionsWidget";

const OrganizationField: FC<FieldProps> = (props): JSX.Element => {
	return <OrganizationSuggestionsWidget {...props} />;
}

export default OrganizationField;

OrganizationField.displayName = "OrganizationField";
