import { FC } from "react";
import { FieldProps } from "@rjsf/core";

import AddressSuggestionsWidget from "../../widgets/AddressSuggestionsWidget";

const AddressField: FC<FieldProps> = (props): JSX.Element => {
	return <AddressSuggestionsWidget {...props} />;
}

export default AddressField;

AddressField.displayName = "AddressField";
