import { FC, useCallback } from 'react';
import { Input } from 'antd';

const INPUT_STYLE = {
	width: '100%',
};

const ColorWidget: FC<RJSFWidget> = ({
	disabled,
	formContext,
	id,
	onBlur,
	onChange,
	onFocus,
	placeholder,
	readonly,
	value,
}): JSX.Element => {
	const { readonlyAsDisabled = true } = formContext;

	const handleChange = useCallback(({ target }: any) => {
		onChange(target.value)
	}, [onChange]);

	const handleBlur = useCallback(({ target }: any) => {
		onBlur(id, target.value)
	}, [id, onBlur]);

	const handleFocus = useCallback(({ target }: any) => {
		onFocus(id, target.value)
	}, [id, onFocus]);

	return (
		<Input
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			placeholder={placeholder}
			style={INPUT_STYLE}
			type="color"
			value={value}
		/>
	);
};

export default ColorWidget;

ColorWidget.displayName = "ColorWidget";
