import { FC } from 'react';
import { Input, InputNumber, AutoComplete } from 'antd';

const INPUT_STYLE = {
	width: '100%',
};

const TextWidget: FC<RJSFWidget> = ({
	// autofocus,
	disabled,
	formContext,
	id,
	// label,
	onBlur,
	onChange,
	onFocus,
	options,
	placeholder,
	readonly,
	// required,
	schema,
	value,
}) => {
	const { readonlyAsDisabled = true } = formContext;

	const handleNumberChange = (nextValue: any) => onChange(nextValue);

	const handleTextChange = ({ target }: any) =>
		onChange(target.value === '' ? options.emptyValue : target.value);

	const handleBlur = ({ target }: any) => onBlur(id, target.value);

	const handleFocus = ({ target }: any) => onFocus(id, target.value);

	const isNumber = (schema.type === 'number' || schema.type === 'integer');

	return (
		<>
			{
				isNumber ? (
					<InputNumber
						disabled={disabled || (readonlyAsDisabled && readonly)}
						id={id}
						name={id}
						onBlur={!readonly ? handleBlur : undefined}
						onChange={!readonly ? handleNumberChange : undefined}
						onFocus={!readonly ? handleFocus : undefined}
						placeholder={placeholder}
						style={INPUT_STYLE}
						type="number"
						value={value}
					/>
				) : schema.type === "string" && schema.examples ? (
					<AutoComplete
						disabled={disabled || (readonlyAsDisabled && readonly)}
						id={id}
						// name={id}
						onBlur={!readonly ? handleBlur : undefined}
						onChange={!readonly ? handleNumberChange : undefined}
						onFocus={!readonly ? handleFocus : undefined}
						options={schema.examples.map((example: string) => ({ value: example }))}
						placeholder={placeholder}
						style={INPUT_STYLE}
						// type={options.inputType || 'text'}
						filterOption={(inputValue: any, option: any) => {
							// TODO: Need to refactor this
							return option?.value?.toUpperCase?.().indexOf(inputValue.toUpperCase()) !== -1;
						}}
						value={value}
					/>
				) : (
					<Input
						disabled={disabled || (readonlyAsDisabled && readonly)}
						id={id}
						name={id}
						onBlur={!readonly ? handleBlur : undefined}
						onChange={!readonly ? handleTextChange : undefined}
						onFocus={!readonly ? handleFocus : undefined}
						placeholder={placeholder}
						style={INPUT_STYLE}
						type={options.inputType || 'text'}
						value={value}
					/>
				)
			}
		</>
	)
};

export default TextWidget;

TextWidget.displayName = "TextWidget";
