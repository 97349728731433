// @ts-nocheck
import { FC } from "react";
import { Input } from "antd";
import ReactInputMask from "react-input-mask";

type MaskedInputProps = {
	disabled?: boolean,
	mask?: string
};

// FIXME: Need to replace with TS
const MaskedInput: FC<MaskedInputProps> = ({
	disabled = false,
	mask = "+7 999 999-99-99",
	maskChar = "X",
	formatChars = {
		"9": "[0-9]",
		"a": "[A-Za-z]",
		"*": "[A-Za-z0-9]"
	},
	...props
}): JSX.Element => {
	if (!disabled) {
		return (
			<ReactInputMask
				mask={mask}
				maskChar={maskChar}
				formatChars={formatChars}
				{...props}
			>
				{(inputProps: any) => <Input {...inputProps} />}
			</ReactInputMask>
		);
	}

	return (
		<Input
			{...props}
			disabled={disabled}
		/>
	);
};

export default MaskedInput;

MaskedInput.displayName = "MaskedInput";
