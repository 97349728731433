import { FC, useMemo } from 'react';
import cx from 'classnames';
import { FieldProps } from '@rjsf/core';

import { withConfigConsumer } from "antd/lib/config-provider/context";

const TitleField: FC<FieldProps> = ({ formContext, id, prefixCls, required, title }): JSX.Element => {
	const { colon = true } = formContext;

	const labelClassName = cx({
		[`${prefixCls}-item-required`]: required,
		[`${prefixCls}-item-no-colon`]: !colon,
	});

	const handleLabelClick = () => {
		if (!id) {
			return;
		}

		const control: HTMLInputElement | null = document.querySelector(`[id="${id}"]`);

		if (control && control.focus) {
			control.focus();
		}
	};

	const label = useMemo(() => {
		if (colon && typeof title === 'string' && title.trim() !== '') {
			return title.replace(/[：:]\s*$/, '');
		};

		return title;
	}, [colon, title]);

	if(title) {
		return (
			<label
				className={labelClassName}
				htmlFor={id}
				onClick={handleLabelClick}
				title={typeof title === 'string' ? title : ''}
			>
				{label}
			</label>
		)
	}

	return <></>;
};

TitleField.displayName = "TitleField";

TitleField.defaultProps = {
	formContext: {},
};
// @ts-ignore
export default withConfigConsumer({ prefixCls: 'form' })(TitleField);
