import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";

// TODO: Need to use urls from widget response
const baseURL = process.env.REACT_APP_BASE_API_URL ?? "";
const headers: RawAxiosRequestHeaders = {
	"Content-Type": "application/json",
	"Charset": "utf-8"
};

// if(process.env.NODE_ENV === "development") {
// 	const { default: initMock } = require("../mocks");

// 	initMock(axios);
// }

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const session = Object.fromEntries(params.entries());

axios.defaults.withCredentials = true;

if(session?.value) {
	axios.defaults.headers.common["x-cloudsigns-session"] = session?.value;
}

declare module "axios" {
	interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HTTPClient {
	protected instance: AxiosInstance;

	public constructor(baseURL: string) {
		this.instance = axios.create({
			baseURL,
			headers
		});

		this.initializeRequestInterceptor();
		this.initializeResponseInterceptor();
	}

	private initializeRequestInterceptor = () => {
		this.instance.interceptors.request.use(
			this.handleRequest,
			this.handleError
		);
	};

	private initializeResponseInterceptor = () => {
		this.instance.interceptors.response.use(
			this.handleResponse,
			this.handleError
		);
	};

	private handleRequest = (request: AxiosRequestConfig) => {
		return request;
	};

	private handleResponse = async (response: AxiosResponse) => {
		const status = response?.status;

		switch (status) {
		case 200:
		case 204:
			return Promise.resolve(response);
		case 404:
			return Promise.reject(response);
		case 401:
			return Promise.reject(response);
		case 403:
			return Promise.reject(response);
		case 500:
			return Promise.reject(new Error("Ошибка сервера"));
		default:
			return Promise.resolve(response);
		}
	};

	protected handleError = (error: Error) => {
		console.error("handleError", error);

		Promise.reject(error);
	};

	public request(config: AxiosRequestConfig) {
		return this.instance(config);
	}

	public get(uri: string, config?: AxiosRequestConfig) {
		return this.instance.get(uri, config);
	}

	public post(uri: string, config?: AxiosRequestConfig) {
		return this.instance.post(uri, config);
	}

	public put(uri: string, config?: AxiosRequestConfig) {
		return this.instance.put(uri, config);
	}

	public delete(uri: string, config?: AxiosRequestConfig) {
		return this.instance.delete(uri, config);
	}

	public updateInstance(baseURL: string) {
		this.instance = axios.create({
			baseURL,
			headers
		});
	}

	public updateInstanceSuffix(suffix: string) {
		this.instance = axios.create({
			baseURL: [baseURL, suffix].filter(Boolean).join("/"),
			headers
		});
	}
}

class Service extends HTTPClient {
	constructor(url?: string) {
		super(url ?? baseURL);

		console.log({ baseURL: url ?? baseURL });
	}
}

export default new Service();
export { Service };

