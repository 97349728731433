import { FC, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Checkbox } from "antd";

const CheckboxesWidget: FC<RJSFWidget> = ({
	autofocus,
	disabled,
	formContext,
	id,
	// onBlur,
	onChange,
	// onFocus,
	options,
	readonly,
	value,
}) => {
	const { readonlyAsDisabled = true } = formContext;
	const { enumOptions, enumDisabled, inline } = options;

	const handleChange = useCallback((nextValue: any) => {
		onChange(nextValue)
	}, [onChange]);

	// const handleBlur = useCallback(({ target }: any) => {
	// 	onBlur(id, target.value)
	// }, [id, onBlur]);

	// const handleFocus = useCallback(({ target }: any) => {
	// 	onFocus(id, target.value)
	// }, [id, onFocus]);

	return !isEmpty(enumOptions) ? (
		<Checkbox.Group
			disabled={disabled || (readonlyAsDisabled && readonly)}
			// id={id}
			name={id}
			// onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			// onFocus={!readonly ? handleFocus : undefined}
			value={value}
		>
			{
				enumOptions.map(({ value: optionValue, label: optionLabel }: any, idx: number) => (
					<span key={optionValue}>
						<Checkbox
							autoFocus={idx === 0 ? autofocus : false}
							disabled={enumDisabled && enumDisabled.indexOf(value) !== -1}
							value={optionValue}
						>
							{optionLabel}
						</Checkbox>

						{!inline && <br />}
					</span>
				))
			}
		</Checkbox.Group>
	) : null;
};

export default CheckboxesWidget;

CheckboxesWidget.displayName = "CheckboxesWidget";
