import { FC } from "react";
import { FieldProps } from "@rjsf/core";

import FIOSuggestionsWidget from "../../widgets/FIOSuggestionsWidget";

const FIOField: FC<FieldProps> = (props): JSX.Element => {
	return <FIOSuggestionsWidget {...props} />;
}

export default FIOField;

FIOField.displayName = "FIOField";
