import { FC } from "react";
import { AutoComplete, Input } from "antd";

import useSuggestions from "../../hooks/useSuggestions";

const InputSearch = Input.Search;

type SuggestionsInputProps = {
	type: SuggestionsType,
	value: string,
	placeholder: string,
	disabled: boolean,
	onSelect: ({ target }: any) => void | undefined,
	onSearch: () => void,
	name: string,
	format: (suggestions: any[]) => any[]
};

const SuggestionsInput: FC<SuggestionsInputProps> = ({ type, value, placeholder, disabled, onSelect, onSearch, format }) => {
	const {
		loading: isSuggestionsLoading,
		data: suggestions,
		error: suggestionsError,
		run: handleGetSuggestions
	} = useSuggestions(
		type,
		{
			manual: true
		}
	);

	console.error({ suggestionsError });

	return (
		<AutoComplete
			value={value}
			// @ts-ignore
			options={format ? format(suggestions ?? []) : suggestions}
			onSelect={onSelect}
			onSearch={onSearch}
			onChange={handleGetSuggestions}
		>
			<InputSearch
				disabled={disabled || isSuggestionsLoading}
				loading={isSuggestionsLoading}
				placeholder={placeholder}
			/>
		</AutoComplete>
	);
}

export default SuggestionsInput;

SuggestionsInput.displayName = "SuggestionsInput";
