import { FC } from "react";
import { Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';

interface CustomLabelProps {
	id?: string | number,
	label: string,
	description: string
};

const CustomLabelDefaultProps = {
	id: "",
	label: "",
	description: ""
};

const CustomLabel: FC<CustomLabelProps> = ({ id, label, description }): JSX.Element => {
	return (
		<>
			{label}
			{
				!!description && (
					<Tooltip title={description}>
						<Typography.Link style={{ marginLeft: 8 }}>
							<QuestionCircleOutlined />
						</Typography.Link>
					</Tooltip>
				)
			}
		</>
	);
}

export default CustomLabel;

CustomLabel.defaultProps = CustomLabelDefaultProps;
