import { FC, useCallback } from "react";

import SuggestionsInput from "../../components/SuggestionsInput";

const INPUT_STYLE = {
	width: '100%',
};

const SuggestionsWidget: FC<RJSFWidget> = ({
	disabled,
	formContext,
	id,
	onBlur,
	onChange,
	onFocus,
	placeholder,
	readonly,
	value,
	options,
	format
}): JSX.Element => {
	const { readonlyAsDisabled = true } = formContext;
	const { type } = options;

	const handleChange = useCallback((value: any, { data }: any) => {
		onChange({ value, ...data });
	}, [onChange]);

	const handleBlur = useCallback(({ target }: any) => {
		onBlur(id, target.value)
	}, [id, onBlur]);

	const handleFocus = useCallback(({ target }: any) => {
		onFocus(id, target.value)
	}, [id, onFocus]);

	return (
		<SuggestionsInput
			disabled={disabled || (readonlyAsDisabled && readonly)}
			/* @ts-ignore */
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			// @ts-ignore
			onSelect={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			placeholder={placeholder}
			style={INPUT_STYLE}
			type={type}
			value={value}
			format={format}
		/>
	);
};

export default SuggestionsWidget;

SuggestionsWidget.displayName = "SuggestionsWidget";
