import { FC } from 'react';
import { Form } from 'antd';
import { FieldProps } from '@rjsf/core';

import WrapIfAdditional from './WrapIfAdditional';
import CustomLabel from '../../components/CustomLabel';

const VERTICAL_LABEL_COL = { span: 24 };
const VERTICAL_WRAPPER_COL = { span: 24 };

const FieldTemplate: FC<FieldProps> = ({
	children,
	classNames,
	description,
	disabled,
	displayLabel,
	// errors,
	// fields,
	formContext,
	help,
	hidden,
	id,
	label,
	onDropPropertyClick,
	onKeyChange,
	rawDescription,
	rawErrors,
	rawHelp,
	readonly,
	required,
	schema,
	// uiSchema,
}): JSX.Element => {

	const {
		colon,
		labelCol = VERTICAL_LABEL_COL,
		wrapperCol = VERTICAL_WRAPPER_COL,
		wrapperStyle,
	} = formContext;

	if (hidden) {
		return <div className="field-hidden">{children}</div>;
	}

	const renderFieldErrors = () => {
		//@ts-ignore
		return [...new Set(rawErrors)].map((error) => (
			<div key={`field-${id}-error-${error}`}>{error}</div>
		))
	};

	return (
		<WrapIfAdditional
			classNames={classNames}
			disabled={disabled}
			formContext={formContext}
			id={id}
			label={label}
			onDropPropertyClick={onDropPropertyClick}
			onKeyChange={onKeyChange}
			readonly={readonly}
			required={required}
			schema={schema}
		>
			{/* {id === 'root' ? (
				children
			) : (

			)} */}
			<Form.Item
				colon={colon}
				// extra={!!rawDescription && description}
				hasFeedback={schema.type !== 'array' && schema.type !== 'object'}
				help={(!!rawHelp && help) || (!!rawErrors && renderFieldErrors())}
				htmlFor={id}
				label={
					/* displayLabel &&  */!!label && (
						<CustomLabel
							id={id}
							label={label}
							// TODO: Need to fix this description
							// description={!!rawDescription && description}
							description={rawDescription}
						/>
					)
				} /* TODO: Need to use displayLabel but false */
				labelCol={labelCol}
				required={required}
				style={{
					...wrapperStyle,
					marginBottom: 0
				}}
				validateStatus={rawErrors?.length ? 'error' : undefined}
				wrapperCol={wrapperCol}
			>
				{children}
			</Form.Item>
		</WrapIfAdditional>
	);
};

export default FieldTemplate;

FieldTemplate.displayName = "FieldTemplate";
