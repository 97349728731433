import { FC } from 'react';
import { Radio } from 'antd';

const RadioButtonWidget: FC<RJSFWidget> = ({
	autofocus,
	disabled,
	formContext,
	id,
	onBlur,
	onChange,
	onFocus,
	options,
	readonly,
	schema,
	value,
}) => {
	const { readonlyAsDisabled = true } = formContext;

	const { enumOptions, enumDisabled } = options;

	const handleChange = ({ target: { value: nextValue } }: any) =>
		onChange(schema.type === 'boolean' ? nextValue !== 'false' : nextValue);

	const handleBlur = ({ target }: any) => onBlur(id, target.value);

	const handleFocus = ({ target }: any) => onFocus(id, target.value);

	return (
		<Radio.Group
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			value={`${value}`}
		>
			{
				enumOptions.map(({ value: optionValue, label: optionLabel }: any, idx: number) => {
					return (
						<Radio.Button
							key={`${optionValue}`}
							autoFocus={idx === 0 ? autofocus : false}
							disabled={enumDisabled && enumDisabled.indexOf(value) !== -1}
							value={`${optionValue}`}
						>
							{optionLabel}
						</Radio.Button>
					)
				})
			}
		</Radio.Group>
	);
};

export default RadioButtonWidget;

RadioButtonWidget.displayName = "RadioButtonWidget";
