import { FC } from "react";

import _AltDateWidget from '../AltDateWidget';

// TODO: Types
const AltDateTimeWidget: FC = (props: any): JSX.Element => {
	const { AltDateWidget } = props.registry.widgets;

	return (
		<AltDateWidget
			showTime
			{...props}
		/>
	)
};

AltDateTimeWidget.displayName = "AltDateTimeWidget";

AltDateTimeWidget.defaultProps = {
	..._AltDateWidget.defaultProps,
	showTime: true,
};

export default AltDateTimeWidget;
