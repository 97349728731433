import { FC, forwardRef, LegacyRef } from "react";
import { withTheme } from "@rjsf/core";

import ErrorBoundary from "../../containers/ErrorBoundary";

import { Theme as AntDTheme } from './theme/antd';

const AntDForm = withTheme(AntDTheme);

const Form: FC<JSONSchemaFormProps> = forwardRef(({
	id,
	schema = {},
	uiSchema = {},
	formData,
	formContext,
	onChange,
	onSubmit,
	onError,
	liveValidate = false,
}, ref: LegacyRef<HTMLButtonElement>): JSX.Element => {
	return (
		<ErrorBoundary>
			<AntDForm
				id={id}
				schema={schema}
				uiSchema={uiSchema}
				formData={formData}
				formContext={formContext}
				onChange={onChange}
				onSubmit={onSubmit}
				onError={onError}
				validate={((_: never, errors: string[]) => errors)}
				liveValidate={liveValidate}
			>
				<button ref={ref} style={{ position: 'fixed', left: -999, opacity: 0 }} />
			</AntDForm>
		</ErrorBoundary>
	)
})

export default Form;
