import { FC } from 'react';

import Alert from 'antd/lib/alert';
import List from 'antd/lib/list';
import Space from 'antd/lib/space';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';

const ErrorList: FC<{ errors: any[] }> = ({ errors }): JSX.Element => {
	const renderErrors = () => (
		<List className="list-group" size="small">
			{
				errors.map((error, idx) => (
					// TODO: Need to use id as prefix key
					<List.Item key={idx}>
						<Space>
							<ExclamationCircleOutlined />
							{error.stack}
						</Space>
					</List.Item>
				))
			}
		</List>
	);

	return (
		<Alert
			className="panel panel-danger errors"
			description={renderErrors()}
			message="Errors"
			type="error"
		/>
	);
};

export default ErrorList;

ErrorList.displayName = "ErrorList";
