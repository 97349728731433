import { FC } from "react";
import { FieldProps } from "@rjsf/core";

const DescriptionField: FC<FieldProps> = ({ description, id }): JSX.Element => {
	/* return (
		<span id={id}>
			{description}
		</span>
	) */

	return <></>
};

export default DescriptionField;

DescriptionField.displayName = "DescriptionField";
