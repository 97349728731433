import { FC } from 'react';
import { Input } from 'antd';

const INPUT_STYLE = {
	width: '100%',
};

const URLWidget: FC<RJSFWidget> = ({
	// autofocus,
	disabled,
	formContext,
	id,
	// label,
	onBlur,
	onChange,
	onFocus,
	options,
	placeholder,
	readonly,
	// required,
	// schema,
	value,
}) => {
	const { readonlyAsDisabled = true } = formContext;

	const handleChange = ({ target }: any) =>
		onChange(target.value === '' ? options.emptyValue : target.value);

	const handleBlur = ({ target }: any) => onBlur(id, target.value);

	const handleFocus = ({ target }: any) => onFocus(id, target.value);

	return (
		<Input
			disabled={disabled || (readonlyAsDisabled && readonly)}
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			placeholder={placeholder}
			style={INPUT_STYLE}
			type="url"
			value={value}
		/>
	);
};

export default URLWidget;

URLWidget.displayName = "URLWidget";
