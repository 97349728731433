import { FC, useCallback } from "react";

import MaskedInput from "../../components/MaskedInput";

const INPUT_STYLE = {
	width: '100%',
};

const MaskedWidget: FC<RJSFWidget> = ({
	disabled,
	formContext,
	id,
	onBlur,
	onChange,
	onFocus,
	placeholder,
	readonly,
	value,
	options
}): JSX.Element => {
	const { readonlyAsDisabled = true } = formContext;
	const { mask, maskChar, formatChars } = options;

	const handleChange = useCallback(({ target }: any) => {
		onChange(target.value)
	}, [onChange]);

	const handleBlur = useCallback(({ target }: any) => {
		onBlur(id, target.value)
	}, [id, onBlur]);

	const handleFocus = useCallback(({ target }: any) => {
		onFocus(id, target.value)
	}, [id, onFocus]);

	return (
		<MaskedInput
			disabled={disabled || (readonlyAsDisabled && readonly)}
			/* @ts-ignore */
			id={id}
			name={id}
			onBlur={!readonly ? handleBlur : undefined}
			onChange={!readonly ? handleChange : undefined}
			onFocus={!readonly ? handleFocus : undefined}
			placeholder={placeholder}
			style={INPUT_STYLE}
			type="text"
			value={value}
			mask={mask}
			maskChar={maskChar}
			formatChars={formatChars}
		/>
	);
};

export default MaskedWidget;

MaskedWidget.displayName = "MaskedWidget";
